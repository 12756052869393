import React from "react";
import { Flex, Box, Container } from "theme-ui";
import Header from "../partials/header";
import Footer from "../partials/footer";

export default (WrappedComponent: React.FC | React.ClassicComponentClass) => {
  const hocComponent = ({ ...props }) => (
    <Flex sx={{ flexDirection: "column", minHeight: "100vh" }}>
      {/* <Header></Header> */}
      <Box sx={{ flex: 1, py: 5 }}>
        <Container variant="small">
          <WrappedComponent {...props} />
        </Container>
      </Box>
    </Flex>
  );

  return hocComponent;
};
