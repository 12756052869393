import React, { useState } from "react";
import { Box, Heading, Button, Text } from "theme-ui";
import { loadStripe } from "@stripe/stripe-js";
import withSimple from "../components/layouts/withSimple";
import { ErrorWithActions, ErrorMessage, Loading } from "../components";

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PUBLISHABLE_KEY as string
);

const Pay = (props) => {
  return (
    <Box py={4} sx={{ textAlign: "center" }}>
      <h2>Version Outdated!</h2>
      <p>
        You are still using an old and unsupported version of the Give a Meal
        app. A newer version of this app is available.
      </p>

      <Box mt={2}>
        <h4>Upgrade by clicking on your platform</h4>
        <a href="https://play.google.com/store/apps/details?id=com.innercitymission.foodbank">
          Android
        </a>
        <a href="https://apps.apple.com/us/app/give-a-meal/id1534705774">
          Apple iOS
        </a>
      </Box>
    </Box>
  );
  const [state, setState] = useState({ loading: true, error: "" });

  const sessionId = props.location.search.split("?session_id=")[1];

  if (sessionId && !state.error) {
    const process = async () => {
      const stripe = await stripePromise;
      const result = await stripe?.redirectToCheckout({
        sessionId,
      });

      if (result?.error) {
        setState({
          ...state,
          loading: false,
          error: result.error.message as string,
        });
      }
    };

    process();
  }

  return (
    <Box>
      {state.loading && <Loading />}
      {state.error && <ErrorWithActions error={state.error} />}
    </Box>
  );
};

export default withSimple(Pay);
